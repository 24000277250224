.homepage {
  &.firstSectionView {
    padding: 60px 16px;

    .titleStyle {
      [data-title] {
        @include fontSize(64px, 120%);

        font-weight: 400;

        @include phone {
          @include fontSize(38px, 120%);
        }
      }
    }

    @include phone {
      padding: 30px 16px;
    }
  }

  &.secondSectionView {
    background-color: $gray900;

    .titleStyle {
      margin: 0 auto 32px;

      [data-title] {
        @include fontSize(16px, 150%);

        color: $gray0;
        font-weight: 400;
      }
    }
  }
}
