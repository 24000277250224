.section {
  padding: 60px 16px 120px;

  .container {
    @include flex(row, center, initial, wrap);

    gap: 48px;
    max-width: 1268px;
    margin: 0 auto;

    @include phone {
      gap: 32px;
    }
  }

  .svg {
    width: auto;
    height: 32px;
  }

  @include phone {
    padding: 60px 16px;
  }
}
